import TcVod from 'vod-js-sdk-v6'
import ajax from './ajax.js'

// 获取临时权限
function getToKen(){
  return ajax({
    url: '/hxclass-management/vod/signature',
    method: 'post'
  }).then(res=>{
    if(res.code == 200 && res.success){
      return res.data
    }
  })
}

/*
  文件上传
  调用方式：
    upload_video({
      file: fileObject,
      progress: (e)=>{},
      success: (e)=>{}
    })
*/
export default function upload_video({
  file,
  progress,
  success
}){
  const tcVod = new TcVod({
    getSignature: getToKen // 前文中所述的获取上传签名的函数
  })
  const uploader = tcVod.upload({
    mediaFile: file,
  })
  uploader.done().then((doneResult)=> {
    // 上传成功后回调
    success(doneResult.fileId)
  }).catch((err)=>{
    this.$message.warning('视频文件上传失败，请重新上传！')
  })
  
  // 视频上传进度
  uploader.on('media_progress', function(info) {
    progress(info)
  })
}