// 机构管理
export default [
  // 学习包
  {
    path: 'LearnPackage',
    component: () => import('@/views/admin/Mechanism/LearnPackage.vue')
  },
  // 学习包详情
  {
    path: 'LearnPackageDetail',
    component: () => import('@/views/admin/Mechanism/LearnPackageDetail.vue')
  },
  // 商品分类
  {
    path: 'goodsClass',
    component: () => import('@/views/admin/Mechanism/goodsClass.vue')
  },
   // 商品列表
   {
    path: 'ProductList',
    component: () => import('@/views/admin/Mechanism/ProductList.vue')
  },
  // 新增商品
  {
    path: 'NewProduct',
    component: () => import('@/views/admin/Mechanism/NewProduct.vue')
  },
  // 机构认证审核
  {
    path: 'agencyCertification',
    component: () => import('@/views/admin/Mechanism/agencyCertification.vue')
  },
  // 机构认证审核
  {
    path: 'agencyDetails',
    component: () => import('@/views/admin/Mechanism/agencyDetails.vue')
  },
  // 用户管理
  {
    path: 'mechanismcrm',
    component: () => import('@/views/admin/Mechanism/mechanismcrm.vue')
  },
  // 轮播图
  {
    path: 'CarouselChart',
    component: () => import('@/views/admin/Mechanism/CarouselChart.vue')
  },
  // 轮播图新建
  {
    path: 'CarouselChartDetail',
    component: () => import('@/views/admin/Mechanism/CarouselChartDetail.vue')
  },
  // 推荐配置
  {
    path: 'Recommend',
    component: () => import('@/views/admin/Mechanism/Recommend.vue')
  },
  // 用户查询
  {
    path: 'userSearch',
    component: () => import('@/views/admin/Mechanism/userSearch.vue')
  },
  // 用户详情
  {
    path: 'userDetail',
    component: () => import('@/views/admin/Mechanism/userDetail.vue')
  },
  
]