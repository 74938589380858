export default [
  { // 用户管理
    path: 'index',
    component: () => import('@/views/admin/userManage/index.vue')
  },
  { // 用户详情
    path: 'userDetail',
    component: () => import('@/views/admin/userManage/userDetail.vue')
  },
  { // 问题反馈
    path: 'ProblemFeedback',
    component: () => import('@/views/admin/userManage/ProblemFeedback.vue')
  },
  { // 账号管理
    path: 'AccountCreat',
    component: () => import('@/views/admin/userManage/AccountCreat.vue')
  },
  { // 账号创建
    path: 'CreateUser',
    component: () => import('@/views/admin/userManage/CreateUser.vue')
  }
  
]