export default [
  { // 课程证书
    path: 'curriculum',
    component: () => import('@/views/admin/Certificate/curriculum.vue')
  },
  { // 学习人员
    path: 'Learner',
    component: () => import('@/views/admin/Certificate/Learner.vue')
  },
  { // 获证人员
    path: 'CertifiedPersonnel',
    component: () => import('@/views/admin/Certificate/CertifiedPersonnel.vue')
  },
  { // 考试证书
    path: 'Examination',
    component: () => import('@/views/admin/Certificate/Examination.vue')
  },
  { // 考试学习人员
    path: 'ExaminationLearners',
    component: () => import('@/views/admin/Certificate/ExaminationLearners.vue')
  },
  { // 考试获证人员
    path: 'ExaminationCertificate',
    component: () => import('@/views/admin/Certificate/ExaminationCertificate.vue')
  },
  { // 考生信息-第一步
    path: 'CandidateInformation',
    component: () => import('@/views/admin/Certificate/CandidateInformation.vue')
  },
  { // 证书生成-第二步
    path: 'GenerateCertificate',
    component: () => import('@/views/admin/Certificate/GenerateCertificate.vue')
  },
  { // 证书物流-第三步
    path: 'CertificateLogistics',
    component: () => import('@/views/admin/Certificate/CertificateLogistics.vue')
  },
  { // 证书信息
    path: 'CertificateInformation',
    component: () => import('@/views/admin/Certificate/CertificateInformation.vue')
  },
  { // 证书修改
    path: 'CertificateModify',
    component: () => import('@/views/admin/Certificate/CertificateModify.vue')
  },
  { // 证书导入列表
    path: 'certificateImport',
    component: () => import('@/views/admin/Certificate/certificateImport.vue')
  },
  { // 导入证书
    path: 'newCertificate',
    component: () => import('@/views/admin/Certificate/newCertificate.vue')
  },
]