// 基础配置
export default [
  //  分析师报名
  {
    path: 'Analyst',
    component: () => import('@/views/admin/Registration/Analyst.vue')
  },
  //  九月上海会议报名记录
  {
    path: 'ShanghaiSignUp',
    component: () => import('@/views/admin/Registration/ShanghaiSignUp.vue')
  },
  //  十月武汉VB报名记录
  {
    path: 'WuhanSignUp',
    component: () => import('@/views/admin/Registration/WuhanSignUp.vue')
  }
]