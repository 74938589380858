export default [
  {
    path: '/',
    redirect: 'loginIndex'
  },
  // 首页
  {
    path: 'loginIndex',
    name: 'loginIndex',
    component: () => import('@/views/login/index.vue')
  }
]
