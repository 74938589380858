import heic2any from "heic2any";
import store from '@/store'

// 提示语判断早中晚
export function FunTimePrompt(){
  const now = new Date(),hour = now.getHours();
  let text = ''
  if(hour < 6){
    text = '凌晨好'
  }else if (hour < 9){
    text = '早上好'
  }else if (hour < 12){
    text = '上午好'
  }else if (hour < 14){
    text = '中午好'
  }else if (hour < 17){
    text = '下午好'
  }else if (hour < 19){
    text = '傍晚好'
  }else if (hour < 22){
    text = '晚上好'
  }else{
    text = '夜里好'
  }
  return text
}

// 金额增加千位符
export function onThousand(e){
  e = Number(e)
  return e.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
}

// 生成一段uuid
export function uuid(){
  let d = new Date().getTime()
  if (window.performance && typeof window.performance.now === 'function') {
    d += performance.now() // 如果可用，请使用高精度计时器
  }
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c == "x" ? r : (r & 0x3) | 0x8).toString(16)
  })
  return uuid
};

// 万能打开附件
export function openFile(url){
  let lastIndex = url.lastIndexOf('.');
  let suffix = url.substring(lastIndex+1)
  if(suffix == 'doc' || suffix == 'docx' || suffix == 'txt' || suffix == 'xls' || suffix == 'xlsx'){
    window.open(url + '?ci-process=doc-preview&dstType=html&copyable=0&htmlParams=eyJwZGZPcHRpb25zIjp7ImlzSW5TYWZlTW9kZSI6dHJ1ZX19');
  } else {
    window.open(url);
  }
}

// 解析预览heic图片文件
export async function viewHeic(url){
  const isHEIC = url.endsWith('.heic');
  if (isHEIC) {
    const response = await fetch(url);
    const blob = await response.blob();
    const imageBlob = await heic2any({ blob });
    const imageUrl = URL.createObjectURL(imageBlob);
    return imageUrl
  } else {
    return url
  }
}

// 是否为考试查询模块
export function isExamSearch(){
  return this.$route.path.indexOf('ExamSearch') != -1;
}

// 敏感数据加密
export function encryption(limits, types, data){
  /**
   *   limits: 加密权限标识,  empty
   *   types: 加密类型
   *   data: 数据
  */
  let result = '' // 返回值
  const all_permission = "*:*:*"; // 超级管理员
  const permissions = store.state.userInfo && store.state.userInfo.permissions
  
  if((permissions.indexOf(all_permission) != -1 || permissions.indexOf(limits) != -1) && limits != "empty"){
    /**
     * 超级管理员或有权限用户时，数据不做加密处理
    */
    result = data
  } else if (types == 'phone'){
    /**
     * 隐藏手机号中间4位
     * @param {phone} 传入数据
     * 格式：182****1314
    */
    // result = data.replace(/^(\d{3})\d+(\d{4})$/, "$1****$2")
    result = data.replace(/^(.{0}).*(.{6})$/, "$1*****$2")
  } else if (types == 'idCard'){
    /**
     * 隐藏身份证中间8位数
     * @param {data} 传入数据
     * 格式：530026******2101
    */
    // result = data.replace(/(.{6}).*(.{4})/, "$1********$2")
    result = data.replace(/(.{0}).*(.{6})/, "$1********$2")
  }
  return result
}

// 数据中心格式处理
export function fun_formatData(data){
  /**
   *   data: 数据
  */
  let resultData = data.split("$$"); // 按数据code和data进行分割区分
  let codeValue = resultData[0].split("||"); // 获取code值， 0 查询成功
  let formObj = {}; // 自定义数据返回格式
  formObj = {
    code: Number(codeValue[0]),
    message: codeValue[1],
    data: [],
  };

  // 查询成功数据处理
  if (formObj.code == 0) {
    let arryLength = resultData[1].split("@@").length;
    let dataArry = "";
    if (arryLength <= 1) {
      // 判断是否需要@@分割
      dataArry = resultData[1].split("##");
    } else {
      let longArry = resultData[1].split("@@");
      let string = "";
      longArry.forEach((element) => {
        string = string + element + "##";
      });
      dataArry = string.split("##");
    }
    dataArry.forEach((element) => {
      let item = element.split("||");
      formObj.data.push({
        name: item[0],
        value: item[1],
        value2: item[2],
        value3: item[3],
        value4: item[4],
      });
    });
  }
  return formObj;
}