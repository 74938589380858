// 订单管理
export default [
  // 线上订单
  {
    path: 'LineOrder',
    component: () => import('@/views/admin/OrderConfig/lineOrder.vue')
  },
  // 订单详情
  {
    path: 'OrderDetail',
    component: () => import('@/views/admin/OrderConfig/orderDetail.vue')
  },
  //机构订单详情
  {
    path: 'orgOrderDetail',
    component: () => import('@/views/admin/OrderConfig/orgorderDetail.vue')
  },
  // 退款详情
  {
    path: 'RefundDetail',
    component: () => import('@/views/admin/OrderConfig/refundDetail.vue')
  },
  // 线下开课
  {
    path: 'LineDownClass',
    component: () => import('@/views/admin/OrderConfig/lineDownClass.vue')
  },
  // 评价管理
  {
    path: 'EvaluateConfig',
    component: () => import('@/views/admin/OrderConfig/evaluateConfig.vue')
  },
  // 发票管理
  {
    path: 'InvoiceConfig',
    component: () => import('@/views/admin/OrderConfig/invoiceConfig.vue')
  },
  // 线下发票管理
  {
    path: 'invoiceConfigOffline',
    component: () => import('@/views/admin/OrderConfig/invoiceConfigOffline.vue')
  },
  // 发票详情
  {
    path: 'InvoiceDetail',
    component: () => import('@/views/admin/OrderConfig/invoiceDetail.vue')
  },
  // 订单补录
  {
    path: 'OrderSupplementaryEntry',
    component: () => import('@/views/admin/OrderConfig/orderSupplementaryEntry.vue')
  },
  // 订单补录(新建，详情)
  {
    path: 'NewOrderSupple',
    component: () => import('@/views/admin/OrderConfig/newOrderSupple.vue')
  },
  // 订单退款
  {
    path: 'RefundList',
    component: () => import('@/views/admin/OrderConfig/refund/index.vue')
  },
  // 新建退款
  {
    path: 'addRefund',
    component: () => import('@/views/admin/OrderConfig/refund/addRefund.vue')
  },
  // 退款详情
  {
    path: 'refunDetail',
    component: () => import('@/views/admin/OrderConfig/refund/refundDetail.vue')
  }
]