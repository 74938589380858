import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import upload from '@/unit/upload.js'
import cosUpload from '@/unit/cosUpload.js'
import config from '@/unit/config.js'
import upload_video from '@/unit/upload_video.js'
import hasPermi from '@/unit/hasPermi.js' // 权限管理
import moment from 'moment'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import { isExamSearch } from '@/unit/fun.js'
import { encryption } from '@/unit/fun.js'

import './assets/css/publicStyle.less'
import './components/QuillEditor/quill.css'
// 引入swiper的css样式
import 'swiper/css/swiper.css'

// ajax请求
import ajax from './unit/ajax'

// 正则表达式
import regular from './unit/regular.js'

// AES加密
import AESCipher from './unit/AESCipher.js'

// ui库
import 'ant-design-vue/dist/antd.less'
import Antd from 'ant-design-vue';
// 样式重置
import '@/assets/css/reset.css'
// 插件滚动加载
import infiniteScroll from 'vue-infinite-scroll'
// 图片放大预览
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Viewer.setDefaults({
  Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})

import { message } from 'ant-design-vue'
Vue.use(message)
message.config({
  duration: 3,
  maxCount: 1
})


Vue.config.productionTip = false
Vue.prototype.$ajax = ajax
Vue.prototype.$regular = regular
Vue.prototype.$upload = upload
Vue.prototype.$cosUpload = cosUpload
Vue.prototype.$upload_video = upload_video
Vue.prototype.$moment = moment
Vue.prototype.$config=config;
Vue.prototype.$encryption = encryption;
Vue.prototype.$AES = new AESCipher('hxclass')
Vue.prototype.$isExamSearch = isExamSearch

Vue.use(Antd);
Vue.use(Viewer)
Vue.use(infiniteScroll)
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)

// 挂载权限管理
Vue.directive('hasPermi', hasPermi)

new Vue({
  router,
  store,
  directives: {infiniteScroll},
  render: h => h(App)
}).$mount('#app')
