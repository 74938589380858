// 促销管理
export default [
    // 套餐管理
    {
        path: 'packageManage',
        component: () =>
            import ('@/views/admin/PromotionManage/packageManage.vue')
    },
    // 新建套餐
    {
        path: 'packageDetail',
        component: () =>
            import ('@/views/admin/PromotionManage/packageDetail.vue')
    },
    // 优惠券管理
    {
        path: 'couponManage',
        component: () =>
            import ('@/views/admin/PromotionManage/couponManage.vue')
    },
    // 新建满减券
    {
        path: 'couponDetail',
        component: () =>
            import ('@/views/admin/PromotionManage/couponDetail.vue')
    },
    // 新建兑换券
    {
        path: 'exchangeDetail',
        component: () =>
            import ('@/views/admin/PromotionManage/exchangeDetail.vue')
    },
    // 新建折扣券
    {
        path: 'discountDetail',
        component: () =>
            import ('@/views/admin/PromotionManage/discountDetail.vue')
    },
    // 领取记录
    {
        path: 'receivingRecord',
        component: () =>
            import ('@/views/admin/PromotionManage/receivingRecord.vue')
    },
    // 兑换券设置
    {
        path: 'exchangeManage',
        component: () =>
            import ('@/views/admin/PromotionManage/exchangeManage.vue')
    },
    // 兑换券新建
    {
        path: 'exchangeAdd',
        component: () =>
            import ('@/views/admin/PromotionManage/exchangeAdd.vue')
    },
    // 库码详情
    {
        path: 'codeDetail',
        component: () =>
            import ('@/views/admin/PromotionManage/codeDetail.vue')
    },
    // 优惠券管理-新开发功能
    {
        path: 'newCouponManage',
        component: () =>
            import ('@/views/admin/PromotionManage/newCouponManage.vue')
    },
    // 优惠券管理新建-新开发功能
    {
        path: 'newCouponDetail',
        component: () =>
            import ('@/views/admin/PromotionManage/newCouponDetail.vue')
    },
    // 优惠券管理/领取用户-新开发功能
    {
        path: 'collectingUsers',
        component: () =>
            import ('@/views/admin/PromotionManage/collectingUsers.vue')
    },
]