let obj = new Object()

if(process.env.NODE_ENV == 'development'){
  // 开发环境
  obj = {
    // 代理前缀
    baseUrl: 'api',
    // 请求地址
    target: 'https://almanage.hxclass.cn',
    // target: 'http://192.168.110.26:8083',
    // 图片地址
    pictureUrl: 'https://static.cdpservice.com',
    // 富文本地址
    uEditorUrl: '/api/hxclass-management/uEditor/config'
  }
}else{ 
  // 生产环境
  obj = {
    // 代理前缀
    baseUrl: '',
    // 请求地址
    // target: 'https://alpha.manage.hxclass.cn',  // 测试
    target: 'https://manage.hxclass.cn',  // 正式
    // 图片地址a
    pictureUrl: 'https://static.cdpservice.com',
    // 富文本地址
    uEditorUrl: '/hxclass-management/uEditor/config'
  }
}

export default obj