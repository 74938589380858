export default [
  { // 个人证书审核
    path: 'CertificateManage',
    component: () => import('@/views/admin/AuditManage/CertificateManage.vue')
  },
  { // 课程购买审核
    path: 'CoursePurchase',
    component: () => import('@/views/admin/AuditManage/CoursePurchase.vue')
  },
  { // 课程购买审核
    path: 'CourseReviewDetails',
    component: () => import('@/views/admin/AuditManage/CourseReviewDetails.vue')
  },
  { // 学生学历审核列表
    path: 'StudentDiscounts',
    component: () => import('@/views/admin/AuditManage/StudentDiscounts.vue')
  },
  { // 学生学历审核详情
    path: 'StudentDiscountsDetail',
    component: () => import('@/views/admin/AuditManage/StudentDiscountsDetail.vue')
  }
]