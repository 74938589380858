<template>
  <div @mousemove="moveEvent" id="app">
    <ConfigProvider :locale="locale">
      <router-view/>
    </ConfigProvider>
  </div>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import { ConfigProvider } from 'ant-design-vue'
export default {
  // 可用组件的哈希表
  components: { ConfigProvider },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      locale: zhCN
    }
  },
  // 事件处理器
  methods: {
    moveEvent(e){
      let path = ["/login/loginIndex"];   //跳转的页面
      if (!path.includes(this.$route.path)) {
        clearTimeout(this.timmer);  //重置定时器
        this.signOut();  // 继续执行退出登录
      }
    },
    signOut() {
      this.timmer = setTimeout(() => {
        this.$store.commit('clearUserInfo')
        this.$router.push('/login/loginIndex')
      }, 1000 * 60 * 60); //1小时到期自动跳转登录页面，
    },
  },
  // 生命周期-实例创建完成后调用
  created () {
    // 判断还原globalData的数据
    if (localStorage.getItem("userInfo")) {
      this.$store.state.userInfo = JSON.parse(localStorage.getItem("userInfo"))
    }
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
#app{
  min-width: 1400px;
  min-height: 100%;
  background: #F6F6FC;
}
</style>
