/*
 *	全局正则表达式
 * 
 * 	@return {Object} 返回表达式及不正确的提示语
 */
export default {
    // 姓名
    name: {
        reg: /^[^0-9]{2,20}$/,
        msg: '姓名格式不正确'
    },

    // 机构名称
    institution_name: {
        reg: /^[^0-9]{1,30}$/,
        msg: '单位或机构名称格式不正确'
    },

    // 手机号
    phone: {
        // reg: /^1[3-9]\d{9}$/,
        reg: /^1\d{10}$/,
        msg: '手机号格式不正确'
    },

    // 十六位进制色值
    color: {
        reg: /^#([0-9a-f]{6}|[0-9a-f]{3})$/i,
        msg: '十六位进制色值格式不正确'
    },

    // 网址
    link: {
        reg: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\*\+,;=.]+$/,
        msg: '链接格式不正确'
    },

    // 身份证号
    id_card: {
        reg: /(^\d{15}&)|(^\d{18})|(^\d{17}(\d|X|x)$)/,
        msg: '身份证号格式不正确'
    },

    // 港澳通行证
    hongKong_card: {
        reg: /^([A-Z]\d{6,10}(\w1)?)$/,
        msg: '港澳通行证格式不正确'
    },

    // 护照
    passport_card: {
        reg: /^1[45][0-9]{7}$|([P|p|S|s]\d{7}$)|([S|s|G|g]\d{8}$)|([Gg|Tt|Ss|Ll|Qq|Dd|Aa|Ff]\d{8}$)|([H|h|M|m]\d{8,10})$/,
        msg: '护照格式不正确'
    },

    // 短信验证码
    dxcode: {
        reg: /^\d{6}$/,
        msg: '验证码为6位纯数字'
    },

    // 邮箱
    email: {
        reg: /^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/,
        msg: '邮箱格式不正确'
    },

    // 职称
    post: {
        reg: /^[\u4e00-\u9fa5]{1,10}$/,
        msg: '职业由1-10位汉字组成'
    },

    // 详细地址
    address_detail: {
        reg: /^(?![0-9]+$)(?![a-zA-Z]+$){1,80}/,
        msg: '详细地址由1-80位数字、字母和汉字组成'
    },

    // 物流单号
    logistics_orderNo: {
        reg: /^[A-Za-z0-9]{6,30}$/,
        msg: '物流单号格式不正确'
    },

    // 密码 - 长度验证
    password_len: {
        reg: /^.{8,30}$/,
        msg: '密码为8~30位'
    },

    // 密码 - 标准验证
    password_normal: {
        reg: /^(?=.*[0-9])(?=.*[A-Za-z]).{8,30}$/,
        msg: '密码由8-30位数字、字母组成'
    },

    // 密码 - 强验证
    password: {
        reg: /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_]).{8,30}/,
        msg: '密码必须由大写字母，小写字母，数字，特殊符号且长度8-30位组成'
    },

    // 大于0的整数
    greater_zero: {
        reg: /^[0-9]*[1-9][0-9]*$/,
        msg: '应输入正整数'
    },

    // 数字
    input_number: {
        reg: /^[0-9]*$/,
        msg: '请输入数字'
    },

    // 0-10以内正整数/^[1-9]$/ /^[0-9]{1}(\.[0-9])?$/
    discount_number: {
        reg: /^[0-9]{1}(\.[0-9])?$/,
        msg: '请输入10以内数字'
    },
    videoReg: {
        reg: /\.(flv|mov|mp4|avi|wmv|mp4v)$/,
        msg: '请上传正确格式的视频文件'
    },
    imgReg: {
        reg: /(\.jpg|\.jpeg|\.png|\.gif)$/i,
        msg: '请上传正确格式的图片文件'
    },
    fileReg: {
        reg: /\.(pdf)(\?.*)?$/,
        msg: '请上传正确格式的文件'
    },
}