export default [
  { // 作业管理
    path: 'workManage',
    component: () => import('@/views/admin/Subjective/workManage.vue')
  },
  { // 作业新建/详情
    path: 'workDetail',
    component: () => import('@/views/admin/Subjective/workDetail.vue')
  },
  { // 学员配置
    path: 'studentManage',
    component: () => import('@/views/admin/Subjective/studentManage.vue')
  },
  { // 学员作答列表
    path: 'answerList',
    component: () => import('@/views/admin/Subjective/answerList.vue')
  },
  { // 学员作答详情
    path: 'answerDetail',
    component: () => import('@/views/admin/Subjective/answerDetail.vue')
  },
  { // 试题批阅
    path: 'paperExamination',
    component: () => import('@/views/admin/Subjective/paperExamination/index.vue')
  },
  { // 作业详情-学员作业列表
    path: 'homeWorkList',
    component: () => import('@/views/admin/Subjective/paperExamination/homeWorkList.vue')
  },
  { // 作业详情-学员作业详情
    path: 'homeWorkDetail',
    component: () => import('@/views/admin/Subjective/paperExamination/homeWorkDetail.vue')
  },
  { // 发放查询
    path: 'correctHistory',
    component: () => import('@/views/admin/Subjective/paperExamination/correctHistory.vue')
  },
]