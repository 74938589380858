// 课程管理
export default [
  // 课程分类
  {
    path: 'CourseClassification',
    component: () => import('@/views/admin/curriculum/CourseClassification.vue')
  },
  // 课程列表
  {
    path: 'CourseList',
    component: () => import('@/views/admin/curriculum/CourseList.vue')
  },
  // 课程列表(改造版本)
  {
    path: 'CourseListPlus',
    component: () => import('@/views/admin/curriculum/CourseListPlus.vue')
  },
  // 课程详情
  {
    path: 'CourseDetails',
    component: () => import('@/views/admin/curriculum/CourseDetails.vue')
  },
  // 目录维护
  {
    path: 'CourseContents',
    component: () => import('@/views/admin/curriculum/CourseContents.vue')
  },
  // 课件维护
  {
    path: 'Courseware',
    component: () => import('@/views/admin/curriculum/Courseware.vue')
  },



  /** 新改版拆分课程维护模块 **/
  {
    path: 'GeneralView',
    component: () => import('@/views/admin/curriculum/NewCourseDetails/GeneralView.vue'),
    children: [
      // 基础信息
      {
        path: 'BasicInformation',
        component: () => import('@/views/admin/curriculum/NewCourseDetails/BasicInformation.vue'),
      },
      // 展示详情
      {
        path: 'DisplayDetails',
        component: () => import('@/views/admin/curriculum/NewCourseDetails/DisplayDetails.vue'),
      },
      // 目录管理
      {
        path: 'Directory',
        component: () => import('@/views/admin/curriculum/NewCourseDetails/Directory.vue'),
      },
      // 观看限制
      {
        path: 'WatchSet',
        component: () => import('@/views/admin/curriculum/NewCourseDetails/WatchSet.vue'),
      },
      // 购买设置
      {
        path: 'PurchaseSet',
        component: () => import('@/views/admin/curriculum/NewCourseDetails/PurchaseSet.vue'),
      },
      // 学习辅助
      {
        path: 'Study',
        component: () => import('@/views/admin/curriculum/NewCourseDetails/Study.vue'),
      },

    ]
  },
]