export default [
  { // 自主学习
    path: 'AutonomousLearning',
    component: () => import('@/views/admin/StudyMange/AutonomousLearning.vue')
  },
  { // 班级学习
    path: 'ClassLearning',
    component: () => import('@/views/admin/StudyMange/ClassLearning.vue')
  },
  { // 班级学员
    path: 'ClassStudents',
    component: () => import('@/views/admin/StudyMange/ClassStudents.vue')
  }
]