// 考试查询
export default [
  // 题目管理
  {
    path: 'TopicManage',
    component: () => import('@/views/admin/Examination/TopicManage.vue')
  },
  // 题目管理
  {
    path: 'NewTopic',
    component: () => import('@/views/admin/Examination/NewTopic.vue')
  },
  // 试卷管理
  {
    path: 'TestPaperManage',
    component: () => import('@/views/admin/Examination/TestPaperManage.vue')
  },
  // 自动组卷
  {
    path: 'automaticPaper',
    component: () => import('@/views/admin/Examination/automaticPaper.vue')
  },
  // 手动组卷
  {
    path: 'manualGear',
    component: () => import('@/views/admin/Examination/manualGear.vue')
  },
  // 手动组卷->试卷预览
  {
    path: 'preview',
    component: () => import('@/views/admin/Examination/preview.vue')
  },
  // 模考管理
  {
    path: 'ModelTestManage',
    component: () => import('@/views/admin/Examination/ModelTestManage.vue')
  },
  // 考生名单
  {
    path: 'ExamineeNameList',
    component: () => import('@/views/admin/Examination/ExamineeNameList.vue')
  },
  // 考试设置
  {
    path: 'ExamSeting',
    component: () => import('@/views/admin/Examination/ExamSeting.vue')
  },
  // 考试报名
  {
    path: 'ExamSignUp',
    component: () => import('@/views/admin/Examination/ExamSignUp.vue')
  },
  // 新建-报名
  {
    path: 'AddSignUp',
    component: () => import('@/views/admin/Examination/AddSignUp.vue')
  },
  // 报名审核
  {
    path: 'RegistrationReview',
    component: () => import('@/views/admin/Examination/RegistrationReview.vue'),
  },
  // 审核详情
  {
    path: 'AuditDetails',
    component: () => import('@/views/admin/Examination/AuditDetails.vue')
  },
  // 成绩管理
  {
    path: 'GradeManage',
    component: () => import('@/views/admin/Examination/GradeManage.vue')
  },
  // 考生成绩
  {
    path: 'ExamineeScore',
    component: () => import('@/views/admin/Examination/ExamineeScore.vue'),
  },
  // 考试照片
  {
    path: 'ExamPicture',
    component: () => import('@/views/admin/Examination/ExamPicture.vue')
  },
  // 查看试卷
  {
    path: 'ViewTestPaper',
    component: () => import('@/views/admin/Examination/ViewTestPaper.vue')
  },
  // 导入第三方考试人员
  {
    path: 'ImportPeople',
    component: () => import('@/views/admin/Examination/ImportPeople.vue')
  },
  // 新增题目
  {
    path: 'AddTestQuestions',
    component: () => import('@/views/admin/Examination/addTestQuestions.vue')
  },
  // 新建模拟考试
  {
    path: 'AddMockExam',
    component: () => import('@/views/admin/Examination/addMockExam.vue')
  },
  // 新建考试
  {
    path: 'AddExam',
    component: () => import('@/views/admin/Examination/addExam.vue')
  },
  // 操作记录
  {
    path: 'operationRecord',
    component: () => import('@/views/admin/Examination/operationRecord.vue')
  },
  // 考试报告
  {
    path: 'ExamReport',
    component: () => import('@/views/admin/Examination/ExamReport.vue')
  },
  // 参考人员
  {
    path: 'ReferenceList',
    component: () => import('@/views/admin/Examination/ReferenceList.vue')
  },
  // 分数段配置
  {
    path: 'ScoreConfiguration',
    component: () => import('@/views/admin/Examination/ScoreConfiguration.vue')
  },
  // 问卷调查
  {
    path: 'questionnaireInvestigation',
    component: () => import('@/views/admin/Examination/questionnaireInvestigation.vue')
  }
]