import Vue from 'vue'
import VueRouter from 'vue-router'
import $store from './../store'

import login from './login'
import admin from './admin'

Vue.use(VueRouter)

const routes = [
  // 默认进入登录页面
  {
    path: '/',
    redirect: 'login'
  },
  // 登录注册页面分组
  {
    path: '/login',
    children: login,
    component: () => import('@/views/routerView.vue')
  },
  // admin页面分组
  {
    path: '/admin',
    name:"admin",
    children: admin,
    component: () => import('@/views/admin/pageHome.vue')
  },
  // 错误页面
  {
    path: '*',
    name: 'error',
    component: () => import('@/views/error.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 路由守卫
router.beforeEach((to, from, next) => {
  // 如果页面路径不是404，则进入判断
  if(!to.params.pathMatch){
    const userInfo = JSON.parse(localStorage.getItem("userInfo")) || {}
    const isLogin = $store.state.userInfo.isLogin
    // 判断是否登录
    if (isLogin || userInfo.isLogin || to.fullPath === '/login/loginIndex') {
      next();
      // if(to.fullPath === '/login/loginIndex') {
      //   return next()
      // }
      // // 白名单限制
      // let whiteList = localStorage.getItem('whiteList');
      // // 没登陆跳转登录页面
      // if(!userInfo.isLogin) {
      //   return next()
      // }
      // if(whiteList?.indexOf(to.path) != -1) {
      //   return next()
      // }
      // next('/error')
    } else {
      next({
        path: '/login/loginIndex'
      })
    }
  }else{
    next()
  }
})



export default router
